const config = {
  DefaultSite: 'slepp',
  APIBase: 'https://api.allnightsky.com/v1',
  ApplicationInsightsEndpoint:
    'InstrumentationKey=b55b049a-9da7-4e98-ba01-3770084a879d;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/',
}

if (process.env.NODE_ENV !== 'production') {
  if (window.location.host.match(/localhost/)) {
    config.APIBase = 'http://localhost:7071/v1'
  } else if (window.location.host.match(/anteek.local/)) {
    config.APIBase = 'http://anteek.local:7071/v1'
  } else if (window.location.host.match(/allnightsky.com/)) {
    config.APIBase = 'https://api.allnightsky.com/v1'
  }
}

export default config
