import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import {
  Night,
  NightList,
  NoteList,
  SiteList,
  Weather,
  WeatherHistory,
} from '@allnightsky/api-types'

import API from '@/api'

import SignalR from '@/signalr'

const api = new API()

export interface RootState {
  title: string
  site: string
  nightList: NightList
  siteList: SiteList
  weather: Weather | null
  weatherHistory: WeatherHistory | null
  notes: NoteList
  timezone: string
}

const store = new Vuex.Store({
  plugins: [SignalR()],
  state: {
    title: '',
    site: 'slepp',
    nightList: [],
    siteList: [],
    weather: null,
    weatherHistory: null,
    notes: {},
    timezone: 'America/Edmonton',
  } as RootState,
  mutations: {
    setTitle(state, title: string) {
      state.title = title
    },
    setSite(state, site: string) {
      state.site = site
    },
    setTimezone(state, zone: string) {
      state.timezone = zone
    },
    updateList(state, list: NightList) {
      state.nightList = list
    },
    updateNotes(state, notes: NoteList) {
      state.notes = notes
    },
    updateNotesForDay(state, { day, notes }) {
      state.notes = { ...state.notes, [day]: notes }
    },
    updateWeather(state, weather: Weather) {
      state.weather = weather
    },
    updateWeatherHistory(state, history: WeatherHistory) {
      state.weatherHistory = history
    },
    updateSiteList(state, list) {
      state.siteList = list
    },
  },
  getters: {
    lastNight: (state): Night | null =>
      state.nightList && state.nightList.length > 0 ? state.nightList[0] : null,
    listLoaded: (state): boolean => state.nightList.length > 0,
    weatherLoaded: (state): boolean => state.weather !== null,
    weatherHistoryLoaded: (state): boolean => state.weatherHistory !== null,
  },
  actions: {
    refreshNotes: ({ commit }) =>
      api.loadNotes().then((notes) => commit('updateNotes', notes)),

    refreshNotesForDay: ({ commit }, day) =>
      api
        .loadNote(day)
        .then((note) => commit('updateNotesForDay', { day, note })),

    //saveNotesForDay: ({dispatch}) => api.saveNotes(day, notes).then(() => dispatch('refreshNotesForDay', day)),
    refreshSiteList: ({ commit }) =>
      api.loadSiteList().then((list) => commit('updateSiteList', list)),

    refreshNightList: ({ commit }, site) =>
      api.loadList(site).then((list) => commit('updateList', list)),

    refreshWeather: ({ commit }) =>
      api.getWeather().then((weather) => commit('updateWeather', weather)),

    refreshWeatherHistory: ({ commit }) =>
      api
        .getWeatherHistory()
        .then((history) => commit('updateWeatherHistory', history)),
  },
})

export default store
