









import Vue from 'vue'
import Layout from './Layout.vue'
import LayoutFooter from './LayoutFooter.vue'
export default Vue.extend({
  components: {
    Layout,
    LayoutFooter,
  },
  metaInfo: {
    title: 'Site Overview',
    titleTemplate: '%s | All Night Sky',
  },
})
