import { HubConnectionBuilder, ILogger, LogLevel } from '@microsoft/signalr'
import { degToCompass, Weather } from '@/api'
import logger from '@/logger'
import config from '@/config'
import { Store, Plugin } from 'vuex'
import { RootState } from '@/store'

class MyLogger implements ILogger {
  log(logLevel: LogLevel, message: string): void {
    switch (logLevel) {
      case LogLevel.Critical:
        logger.fatal(message)
        break
      case LogLevel.Error:
        logger.error(message)
        break
      case LogLevel.Warning:
        logger.warn(message)
        break
      case LogLevel.Information:
        logger.info(message)
        break
      case LogLevel.Debug:
        logger.debug(message)
        break
    }
  }
}

export const SignalRClient = new HubConnectionBuilder()
  .configureLogging(new MyLogger())
  .withUrl(config.APIBase)
  .withAutomaticReconnect()
  .build()

let connectionPromise: Promise<void> | null = null

export default function createSignalRPlugin(): Plugin<RootState> {
  return (store: Store<RootState>) => {
    logger.debug('Initializing Vuex SignalR store plugin...')
    SignalRClient.on('weather', (weather) => {
      weather = {
        temp: (((weather.tempf - 32) * 5) / 9).toFixed(1),
        wind: (weather.windspdmph_avg10m * 1.60934).toFixed(1),
        winddir: weather.winddir_avg10m,
        windcard: degToCompass(weather.winddir_avg10m),
        humidity: weather.humidity,
      } as Weather
      logger.debug(weather, 'Received new weather report')
      store.commit('updateWeather', weather)
    })
    let previousSite = store.state.site
    store.subscribe((mutation, state) => {
      if (mutation.type == 'setSite') {
        if (previousSite != state.site) {
          if (previousSite) {
            logger.debug(
              `Leaving group ${previousSite} in favour of group ${state.site}`
            )
            connectionPromise?.then(() =>
              SignalRClient.invoke('joinLeaveGroup', previousSite, 'remove')
            )
          }
          if (state.site) {
            logger.debug(`Joining group ${state.site} after mutation`)
            return connectionPromise?.then(() =>
              SignalRClient.invoke('joinLeaveGroup', state.site, 'add')
            )
          }
        }
        previousSite = state.site
        return
      }
    })
    connectionPromise = SignalRClient.start()
  }
}

export class Client {
  async joinGroup(group: string): Promise<unknown> {
    logger.info(`Joining group ${group} to SignalR`)
    return connectionPromise?.then(() =>
      SignalRClient.invoke('joinLeaveGroup', group, 'add')
    )
  }
  async leaveGroup(group: string): Promise<unknown> {
    logger.info(`Leaving group ${group} from SignalR`)
    return connectionPromise?.then(() =>
      SignalRClient.invoke('joinLeaveGroup', group, 'remove')
    )
  }
}
