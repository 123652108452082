/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { createLogger, stdSerializers } from 'browser-bunyan'
import {
  LogStream,
  DEBUG,
  ERROR,
  FATAL,
  TRACE,
  INFO,
  WARN,
} from '@browser-bunyan/levels'
import { ConsoleFormattedStream } from '@browser-bunyan/console-formatted-stream'
import appInsights from '@/appinsights'
import { SeverityLevel } from '@microsoft/applicationinsights-web'

class AppInsightsStream implements LogStream {
  write(rec: { level: number; msg: string }) {
    let level: SeverityLevel = SeverityLevel.Verbose
    switch (rec.level) {
      case FATAL:
        level = SeverityLevel.Critical
        break
      case ERROR:
        level = SeverityLevel.Error
        break
      case WARN:
        level = SeverityLevel.Warning
        break
      case INFO:
        level = SeverityLevel.Information
        break
      case DEBUG:
        level = SeverityLevel.Verbose
        break
      case TRACE:
        level = SeverityLevel.Verbose
        break
    }
    appInsights.trackTrace({
      severityLevel: level,
      message: rec.msg,
    })
  }
}

// TODO: browser-bunyan doesn't export these, so this can't export the result of createLogger...
type LoggerOptions = {
  name: string
  streams?: Array<StreamOptions>
  serializers?: Object
  src?: boolean
  stream?: LogStream
  level?: string | number
}

type StreamOptions = {
  level?: string | number
  stream: LogStream
}

interface Logger {
  addStream(): void
  addSerializers(): void
  child(options?: LoggerOptions, simple?: boolean): Logger

  level(level: string | number): void
  level(): number

  levels(stream: string | number, level: string | number): void
  levels(stream: string): number
  levels(): Array<number>

  // trace
  trace(fields: object, msg: string, ...args: any[]): void
  trace(err: Error, msg: string, ...args: any[]): void
  trace(msg: string, ...args: any[]): void
  trace(): boolean

  // debug
  debug(fields: object, msg: string, ...args: any[]): void
  debug(err: Error, msg: string, ...args: any[]): void
  debug(msg: string, ...args: any[]): void
  debug(): boolean

  // info
  info(fields: object, msg: string, ...args: any[]): void
  info(err: Error, msg: string, ...args: any[]): void
  info(msg: string, ...args: any[]): void
  info(): boolean

  // warn
  warn(fields: object, msg: string, ...args: any[]): void
  warn(err: Error, msg: string, ...args: any[]): void
  warn(msg: string, ...args: any[]): void
  warn(): boolean

  // error
  error(fields: object, msg: string, ...args: any[]): void
  error(err: Error, msg: string, ...args: any[]): void
  error(msg: string, ...args: any[]): void
  error(): boolean

  // fatal
  fatal(fields: object, msg: string, ...args: any[]): void
  fatal(err: Error, msg: string, ...args: any[]): void
  fatal(msg: string, ...args: any[]): void
  fatal(): boolean
}

const logger: Logger = createLogger({
  name: 'allnightsky',
  streams: [
    {
      level: DEBUG,
      stream: new ConsoleFormattedStream({ logByLevel: true }),
    },
    {
      level: INFO,
      stream: new AppInsightsStream(),
    },
  ],
  serializers: stdSerializers,
  src: true,
})

export default logger
