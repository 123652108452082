import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/site/:siteId',
    props: true,
    component: () =>
      import(/* webpackChunkName: "site" */ '@/components/Site.vue'),
  },
  {
    path: '/site/:site/night/:date',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "single-night" */ '@/components/SingleNight.vue'
      ),
  },
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Landing.vue'),
  },
  {
    path: '/about',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/components/About.vue'),
  },
  {
    path: '/legal',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/components/Legal.vue'),
  },
  {
    path: '/*',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '@/components/NotFound.vue'),
  },
]

export default routes
