<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="secondary">
      <b-navbar-brand to="/">
        <img
          src="../assets/space.svg"
          :style="{ height: '30px', padding: '0px', marginTop: '-5px' }"
        />
        All Night Sky
      </b-navbar-brand>
      <div class="d-none d-md-block">{{ title }}</div>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/about"><b-icon-moon></b-icon-moon> About</b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              <b-icon-clock /> {{ tzShort }}
            </template>
            <b-dropdown-item @click="setTimeZone('America/Edmonton')">
              Local Timezone
            </b-dropdown-item>
            <b-dropdown-item @click="setTimeZone('UTC')">
              Camera Timezone
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="container" class="pt-2 pb-2">
      <router-view />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import logger from '@/logger'
import { BIconMoon, BIconClock } from 'bootstrap-vue'

export default Vue.extend({
  components: {
    BIconMoon,
    BIconClock,
  },
  computed: {
    ...mapState(['timezone', 'title']),
    tzShort: function () {
      return this.timezone != null
        ? moment.tz.zone(this.timezone).abbr(new Date())
        : 'Timezone'
    },
  },
  methods: {
    setTimeZone: function (zone) {
      logger.info('User set timezone to %s', zone)
      this.$store.commit('setTimezone', zone)
    },
  },
})
</script>

<style lang="scss">
@use "sass:color";
@import '@/assets/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
//body {
//  background: black;
//}
#container {
  //height: 100%;
  //min-height: 100%;
  margin: 1vw;
  background: $content-bg;
  border-radius: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  background-color: $body-bg;
  color: $body-color;
  height: 100%;
}
</style>
