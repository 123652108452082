import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web'
import config from '@/config'

const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.ApplicationInsightsEndpoint,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: false,
    distributedTracingMode: DistributedTracingModes.W3C,
  },
})
appInsights.loadAppInsights()
// appInsights.queue.push(() => {
//     appInsights.addTelemetryInitializer((envelope) => {
//       envelope.tags["ai.cloud.role"] = "allsky-frontend";
//     });
// });

export default appInsights
