import appInsights from '@/appinsights'

import Vue from 'vue'

Vue.config.productionTip = false

import VueAppInsights from 'vue-application-insights'

import {
  LayoutPlugin,
  ButtonPlugin,
  ModalPlugin,
  ImagePlugin,
  NavbarPlugin,
  TableSimplePlugin,
} from 'bootstrap-vue'

//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import VueVisibility from 'vue-visibility-change'

import routes from '@/routes'

//import './style.scss'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

const router = new VueRouter({
  mode: 'history',
  routes,
})

Vue.use(VueAppInsights, {
  appInsights,
  trackInitialPageView: true,
  router,
})

Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(VueVisibility)

// Main Bootstrap Vue components
Vue.use(LayoutPlugin)
Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)
Vue.use(ImagePlugin)
Vue.use(NavbarPlugin)
Vue.use(TableSimplePlugin)

import App from '@/components/App.vue'

import store from '@/store'

new Vue({
  router,
  store,
  components: { App },
  render: (h) => h(App),
}).$mount('#app')
